<!-- 
	This is the Pricing page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
	<a-card :bordered="false" class="card-product header-solid mb-24">
  <div style="transform: scale(0.9)">
		<!-- Pricing Headings -->
		<div class="pricing-header" >
			<div class="content">
				<h3 class="mb-5">产品价格</h3>
				<p class="text-lg">根据您的业务需求，选择适合的增值服务</p>
			</div>
		</div>
		<!-- / Pricing Headings -->

		
		<div class="pricing-wrapper" style="margin-top: 40px">

			<!-- Subscription Period Slider -->
			<div class="sliding-tab">
				<ul>
					<li class="tab"><a href="#" @click.prevent="subscriptionChange('monthly', $event)">月付</a></li>
					<li class="tab"><a href="#" @click.prevent="subscriptionChange('annual', $event)">年付 (8折优惠)</a></li>
				</ul>
				<div ref="tabBg" class="tab-bg"></div>
			</div>
			<!-- Subscription Period Slider -->

			<!-- Main Container -->
			<div class="container" style="margin-top: 40px">
				<a-row type="flex" :gutter="[24,24]">
					<a-col :span="24" :lg="8">
						
						<!-- Pricing card -->
						<a-card class="card-pricing header-solid" :bodyStyle="{padding: '20px'}">
							<a-tag class="bg-gray-4 text-gray-9 font-bold">
								免费体验
							</a-tag>
							<h1 class="font-semibold">
								<small>$</small>
								<span v-if="subscription == 'annual'">0</span>
								<span v-else>0</span>
							</h1>

							<ul class="list-pricing">
								<li>
									<a-avatar :size="24" class="bg-dark">
										<a-icon type="check" />
									</a-avatar>
									跟单欧意交易员
								</li>
                <li>
                  <a-avatar :size="24" class="bg-dark">
                    <a-icon type="check" />
                  </a-avatar>
                  欧意模拟盘/实盘
                </li>
                <li>
                  <a-avatar :size="24" class="bg-dark">
                    <a-icon type="check" />
                  </a-avatar>
                  普通交易 (延迟2-4秒)
                </li>
								<li>
									<a-avatar :size="24" class="bg-dark">
										<a-icon type="check" />
									</a-avatar>
									交易消息通知
								</li>
								<li>
									<a-avatar :size="24">
										<a-icon type="minus" />
									</a-avatar>
                  资产限额2000USDT
								</li>
                <li>
                  <a-avatar :size="24">
                    <a-icon type="minus" />
                  </a-avatar>
                  实盘需单独购买海外固定IP
                </li>
                <li>
                  <a-avatar :size="24">
                    <a-icon type="minus" />
                  </a-avatar>
                  基础交易配置
                </li>
<!--								<li>-->
<!--									<a-avatar :size="24">-->
<!--										<a-icon type="minus" />-->
<!--									</a-avatar>-->
<!--									普通爬虫服务-->
<!--								</li>-->
							</ul>
							
<!--							<a-button type="dark" block>-->
<!--								JOIN <a-icon type="arrow-right" class="ml-5" />-->
<!--							</a-button>-->
						</a-card>
						<!-- / Pricing card -->
						
					</a-col>
					<a-col :span="24" :lg="8">
						
						<!-- Pricing card -->
						<a-card class="card-pricing header-solid" :bodyStyle="{padding: '20px'}">
							<a-tag class="bg-gray-4 text-gray-9 font-bold">
								VIP会员
							</a-tag>
							<h1 class="font-semibold">
								<small>$</small>
                <span v-if="subscription == 'annual'">192 <span style="text-decoration: line-through;font-size: medium"> 240 </span></span>
								<span v-else>20</span>
							</h1>

							<ul class="list-pricing">
								<li>
									<a-avatar :size="24" class="bg-dark">
										<a-icon type="check" />
									</a-avatar>
									跟单币安交易员
								</li>
                <li>
                  <a-avatar :size="24" class="bg-dark">
                    <a-icon type="check" />
                  </a-avatar>
                  币安实盘
                </li>
                <li>
                  <a-avatar :size="24" class="bg-dark">
                    <a-icon type="check" />
                  </a-avatar>
                  交易消息通知
                </li>
								<li>
									<a-avatar :size="24" class="bg-dark">
										<a-icon type="check" />
									</a-avatar>
									赠送30天固定海外IP
								</li>
                <li>
                  <a-avatar :size="24" class="bg-dark">
                    <a-icon type="check" />
                  </a-avatar>
                  高级交易配置
                </li>
                <li>
                  <a-avatar :size="24" class="bg-dark">
                    <a-icon type="check" />
                  </a-avatar>
                  极速交易 (延迟1秒左右)
                </li>
                <li>
                  <a-avatar :size="24">
                    <a-icon type="minus" />
                  </a-avatar>
                  资产限额5000USDT
                </li>
<!--								<li>-->
<!--									<a-avatar :size="24">-->
<!--										<a-icon type="minus" />-->
<!--									</a-avatar>-->
<!--                  会员爬虫服务-->
<!--								</li>-->
<!--								<li>-->
<!--									<a-avatar :size="24">-->
<!--										<a-icon type="minus" />-->
<!--									</a-avatar>-->
<!--                  定制功能需求-->
<!--								</li>-->
							</ul>
							
							<a-button v-if="is_vip" type="primary" block>
								续费会员 (剩余时间：{{ vip_days }}天)
							</a-button>
              <a-button v-else-if="!is_vip" type="primary" block>
                开通会员
              </a-button>
						</a-card>
						<!-- / Pricing card -->
						
					</a-col>
					<a-col :span="24" :lg="8">
						
						<!-- Pricing card -->
						<a-card class="card-pricing header-solid" :bodyStyle="{padding: '20px'}">
							<a-tag class="bg-gray-4 text-gray-9 font-bold">
								私有化部署
							</a-tag>
							<h1 class="font-semibold">
								<span v-if="subscription == 'annual'">联系客服</span>
								<span v-else>联系客服</span>
							</h1>

							<ul class="list-pricing">
								<li>
									<a-avatar :size="24" class="bg-dark">
										<a-icon type="check" />
									</a-avatar>
                  会员全部功能
								</li>
								<li>
									<a-avatar :size="24" class="bg-dark">
										<a-icon type="check" />
									</a-avatar>
                  资产限额2万USDT起 (上不封顶)
								</li>
                <li>
                  <a-avatar :size="24" class="bg-dark">
                    <a-icon type="check" />
                  </a-avatar>
                  极速交易 (延迟0.5秒左右)
                </li>
								<li>
									<a-avatar :size="24" class="bg-dark">
										<a-icon type="check" />
									</a-avatar>
                  专属交易信号源
								</li>
								<li>
									<a-avatar :size="24" class="bg-dark">
										<a-icon type="check" />
									</a-avatar>
									专属爬虫服务器集群
								</li>
                <li>
                  <a-avatar :size="24" class="bg-dark">
                    <a-icon type="check" />
                  </a-avatar>
                  定制功能需求
                </li>
                <li>
                  <a-avatar :size="24" class="bg-dark">
                    <a-icon type="check" />
                  </a-avatar>
                  亚马逊服务器 (交易所内网)
                </li>
							</ul>

              <a-popover v-if="!is_private" trigger="click">
                <template slot="content">
                  <img src="images/qr.png" style="width: 100px"/>
                </template>
                <a-button type="dark" block>
                  联系客服 <a-icon type="message" class="ml-5" />
                </a-button>
              </a-popover>
              <a-button v-else-if="is_private" type="dark" block>
                剩余天数：{{ private_days }}天
              </a-button>
						</a-card>
						<!-- / Pricing card -->
						
					</a-col>
				</a-row>

<!--				<h6 class="text-center text-gray-7 font-semibold">More than 50+ brands trust Soft</h6>-->

        <!--Clients-->
<!--				<a-row class="row-clients mt-20">-->
<!--					<a-col :span="12" :md="8" :lg="4">-->
<!--						<img src="images/logos/gray-logos/logo-coinbase.svg" alt="logo_coinbase">-->
<!--					</a-col>-->
<!--					<a-col :span="12" :md="8" :lg="4">-->
<!--        				<img src="images/logos/gray-logos/logo-nasa.svg" alt="logo_nasa">-->
<!--					</a-col>-->
<!--					<a-col :span="12" :md="8" :lg="4">-->
<!--        				<img src="images/logos/gray-logos/logo-netflix.svg" alt="logo_netflix">-->
<!--					</a-col>-->
<!--					<a-col :span="12" :md="8" :lg="4">-->
<!--        				<img src="images/logos/gray-logos/logo-pinterest.svg" alt="logo_pinterest">-->
<!--					</a-col>-->
<!--					<a-col :span="12" :md="8" :lg="4">-->
<!--        				<img src="images/logos/gray-logos/logo-spotify.svg" alt="logo_spotify">-->
<!--					</a-col>-->
<!--					<a-col :span="12" :md="8" :lg="4">-->
<!--        				<img src="images/logos/gray-logos/logo-vodafone.svg" alt="logo_vodafone">-->
<!--					</a-col>-->
<!--				</a-row>-->
				<!-- / Clients -->

				<!-- FAQ -->
				<a-row type="flex">
					<a-col :span="24" :md="12" class="mx-auto">
						<h2 class="text-center">常见问题</h2>
<!--						<p class="text-center text-lg">Frequently Asked Questions</p>-->
          </a-col>
				</a-row>

				<a-row type="flex">
					<a-col :span="24" :md="20" class="mx-auto">
				
						<a-collapse v-model="faq" :bordered="false" accordion>
							<a-collapse-panel key="1" header="可以免费试用吗？" :showArrow="false" class="collapse-header">
								<p>
									跟单猿支持免费试用！你可以接入OKX的模拟盘API，使用模拟盘来体验跟单猿的功能。
								</p>
								<a-icon slot="extra" :type="faq == 1 ? 'minus' : 'plus'" />
							</a-collapse-panel>
							<a-collapse-panel key="2" header="为什么需要海外固定IP？" :showArrow="false" class="collapse-header">
								<p>
                  如果需要尝试使用实盘跟单，你至少需要一个固定的海外IP，用于爬取交易信息和下单交易。出于安全因素的考虑，对于有交易权限的API，
                  交易所要求需要绑定固定的海外IP。你可以在网站直接购买海外IP，所有的海外IP均由如快代理、芝麻代理这些第三方IP服务商提供技术支持，
                  跟单猿只是做了技术对接，价格透明。VPN并不能替代代理IP的作用。
								</p>
								<a-icon slot="extra" :type="faq == 2 ? 'minus' : 'plus'" />
							</a-collapse-panel>
							<a-collapse-panel key="3" header="为什么免费的用户只能有2000U的资金额度？" :showArrow="false" class="collapse-header">
								<p>
									出于交易延迟的考虑，免费用户使用代理IP进行交易，交易延迟会较大，为避免在剧烈波动的行情中影响收益，我们才限制了资金额度。
                  如果有更多的资金交易需求，可以购买会员服务。
								</p>
								<a-icon slot="extra" :type="faq == 3 ? 'minus' : 'plus'" />
							</a-collapse-panel>
							<a-collapse-panel key="4" header="如何才能拥有更低的交易延迟？" :showArrow="false" class="collapse-header">
								<p>
                  为保证交易质量，最多只会有10名会员共用一个交易服务器，交易延迟会较小，通常在1秒左右。
                  如果需要更低的交易延迟，可以联系我们购买私有化部署服务，我们将采用亚马逊服务器进行服务部署，交易速度遥遥领先阿里云和腾讯云。
								</p>
								<a-icon slot="extra" :type="faq == 4 ? 'minus' : 'plus'" />
							</a-collapse-panel>
							<a-collapse-panel key="5" header="除了交易所是否还能跟其他APP的交易员？" :showArrow="false" class="collapse-header">
								<p>
									跟单某些特殊渠道的APP，可以联系客服进行私有化定制。出于安全和成本的因素考虑，这些渠道仅会开放给私有化部署的用户使用。
								</p>
								<a-icon slot="extra" :type="faq == 5 ? 'minus' : 'plus'" />
							</a-collapse-panel>
						</a-collapse>
				
					</a-col>
				</a-row>
				<!-- / FAQ -->

			</div>
			<!-- / Main Container -->


		</div>
  </div>
  </a-card>
</template>

<script>

	import http from "@/utils/http";

  export default ({
		data() {
			return {

				// Subscription type
				subscription: 'monthly',

				// Active faq card
				faq: 1,

        // 用户信息
        is_vip: false,
        is_partner: false,
        vip_days: 0,
        private_days: 0,

			}
		},
		methods: {

			// Handle subscription change and slides
			subscriptionChange( subscription, event ) {

				if( subscription == this.subscription ){
					return ;
				}

				this.subscription = subscription ;

				const tabBg = this.$refs.tabBg,
					  target = event.target ;
				
            	tabBg.style.width = target.offsetWidth + 'px';
            	tabBg.style.height = target.offsetHeight + 'px';
            	tabBg.style.transform = 'translate3d(' + target.offsetLeft + 'px, 0px, 0px)';
			},

      // 获取用户信息
      async fetchUserData() {
        try {
          const response = await http.get('/api/login/?token=' + localStorage.getItem("token"));
          if (response.data.code === 0) {
            this.is_vip = response.data.data.is_vip;
            this.is_private = response.data.data.is_private;
            this.vip_days = response.data.data.vip_days;
            this.private_days = response.data.data.private_days;
          } else {
            return;
          }
        } catch (error) {
          console.error('获取用户数据失败:', error);
        }
      },
			
		},
    created() {
      this.fetchUserData();
    },
	})

</script>

<style lang="scss" scoped>
@import '../scss/base/_variables';
//@import '../scss/pages/pricing.scss';
.sliding-tab {
  text-align: center;
  width: 430px;
  margin: auto;
  background: #f8f9fa;
  border-radius: .75rem;
  position: relative;
  padding: 4px;

  ul {
    display: flex;
    position: relative;
    z-index: 2;
    padding: 0;
    margin: 0;
  }
  li.tab {
    display: block;
    flex: 1 1 0;
    border-radius: .5rem;
  }
  a {
    display: block;
    padding: .5rem 1rem;
    font-size: 16px;
    color: #344767;
  }
  .tab-bg {
    box-shadow: 0 1px 5px 1px #ddd;
    transition: transform .2s ease;
    transform: translate3d(0px, 0px, 0px);
    background: #fff;
    width: 211px;
    height: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 4px;
    z-index: 1;
    border-radius: 8px;
  }
}

.collapse-header {
  font-size: 20px;
  font-weight: 600;
  padding: 16px;
  border-bottom: 1px solid #d9d9d9;
  color: #67748e;
  color: $color-gray-8;
  //margin-bottom: 16px;
}
.collapse-header p {
  font-size: 16px;
  font-weight: 400;
}



</style>